import { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route, useParams, useNavigate } from "react-router-dom";
import Layout from "./components/Layout/Layout";
import Home from "./components/Home/Home";
import Search from "./components/Search/Search";
import Contact from "./components/Contact";
import Error from "./components/Error/Error";
import Apropos from "./components/Apropos"
import SearchBarSearchPage from "./components/Search/SearchBar";
import Map from "./components/Map/Map";
import "./App.css"
import ApiService from './services/Api';
import Loader from "./components/Utils/Loader";
import ModalResults from "./components/Page/ModalResults";
import { Chart, ChartDNB, ChartBAC, ChartDNBStudent, ChartBACStudent } from "./components/Chart/Chart.js"
// Import jQuery
import $ from 'jquery';

function formatDateFr(dateBadFormat) {
	const date = new Date(dateBadFormat);
	return date.getFullYear();
}

function Element({ searchQuery, setSearchQuery, data }) {
	const navigate = useNavigate();
	const { id } = useParams();
	const [element, setElement] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const [phoneNumber, setPhoneNumber] = useState("Voir le numéro");
	const [bool, setBoolean] = useState(true);

	useEffect(() => {
		const api = new ApiService();
		setIsLoading(true);
		api.getFullData(id).then(element => {
			if (element === "api_call_error")
				navigate("/error");
			setElement(element);
			document.title = element.nom_etablissement + " - " + element.nom_commune;
			document.querySelector('meta[name="description"]').setAttribute('content', element.nom_etablissement + " - " + element.adresse_1 + " " + element.code_commune + " " + element.nom_commune + " - " + element.statut_public_prive + " - " + element.identifiant_de_l_etablissement);
			setIsLoading(false);
		});
	}, [navigate, id]);

	const handleClickCall = () => {
		bool ? setPhoneNumber(element.telephone ? element.telephone : "Non renseigné") : setPhoneNumber("Voir le numéro");
		setBoolean(!bool);
	};

	const copyToClipboard = (text) => {
		var $temp = $("<input>");
		$("body").append($temp);
		$temp.val(text).select();
		document.execCommand("copy");
		$temp.remove();
	};

	function formatDateFr(dateBadFormat) {
		const date = new Date(dateBadFormat);
		return date.getFullYear();
	}

	return (
		<div className="main">
			<div className="SearchBar">
				<SearchBarSearchPage searchQuery={searchQuery} setSearchQuery={setSearchQuery} data={data} />
			</div>
			{isLoading ? <Loader isLoading={isLoading} /> :
				<div className="schoolPage">
					<>
						<div className="schoolPageHead">
							<div className="dates mb-3 me-5">
								<span>Mise à jour education.gouv.fr : le {element.record_timestamp}</span>
							</div>
							<div className="card-container shadowed row mx-5 big-resize">
								<div className="col-md-3">
									<div className="left-card-content">
										<p className="text-uppercase text-margin text-center fw-bold h3">{element.nom_etablissement}</p>
										<div className="id_status">
											<p className="text-center text-secondary">UAI: {element.identifiant_de_l_etablissement} • {element.etat === "OUVERT" ? <span className="status_open">{element.etat} <i className="fa-solid fa-circle-check"></i></span> : <span className="status_closed">{element.etat} <i className="fa-solid fa-circle-xmark"></i></span>}</p>
										</div>
									</div>
								</div>
								<div className="col-md-6">
									<div className="row top-middle">
										<div className="col-md-12">
											<table className="w-100">
												<tbody>
													<tr>
														<td>Adresse :</td>
														<td className="text-capitalize">{element.adresse_1 ? element.adresse_1 : "Non renseigné"}, {element.code_postal ? element.code_postal : "Non renseigné"} {element.nom_commune ? element.nom_commune : "Non renseigné"} <a href="#bottom-map" className="text-decoration-none text-orange fw-normal"><i className="fa-solid fa-location-dot"></i> Voir sur la carte</a></td>
													</tr>
													<tr>
														<td>Statut :</td>
														<td>{element.statut_public_prive ? element.statut_public_prive + (element.type_contrat_prive != "SANS OBJET" && element.type_contrat_prive ? ' (' + element.type_contrat_prive + ')' : '') : "Non renseigné"}</td>
													</tr>
													<tr>
														<td>Effectif :</td>
														<td>{element.nombre_d_eleves ? element.nombre_d_eleves + " élèves" : "Non renseigné"} <span className="text-secondary">(donnée {element.date_maj_ligne ? formatDateFr(element.date_maj_ligne) : "inconnue"})</span></td>
													</tr>
													<tr>
														<td>Ouverture de l'établissement :</td>
														<td>{element.date_ouverture ? element.date_ouverture : "Non renseigné"}</td>
													</tr>
												</tbody>
											</table>
										</div>
									</div>
								</div>
								<div className="col-md-3">
									<div className="right-card-content">
										<div className="d-flex flex-column buttons">
											<ModalResults element={element} />
											<button type="button" className="btn btn-ef text-light mx-auto w-100 mt-2" onClick={handleClickCall}><i className="fa-solid fa-phone"></i> {phoneNumber}</button>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div id="cards">
							<div className="row m-5 big-resize flex-column flex-md-row">
								<div id="infoBox" className="col-md-6 bg col-with-margin me-2 mb-3 mb-md-0">
									<div className="row top-middle p-3">
										<h3 className="mb-3 p-0 fw-bold">Informations Juridiques de l'établissement</h3>
										<table className="w-100">
											<tbody>
												<tr>
													<td>SIREN :</td>
													<td>{element.siren_siret ? (<span>{element.siren_siret.substring(0, 9).replace(/(.{3})/g, "$1 ")} <i className="fa-regular fa-clipboard" onClick={() => copyToClipboard(element.siren_siret.substring(0, 9).replace(/(.{3})/g, "$1 "))}></i></span>) : ("Non renseigné")}</td>
												</tr>
												<tr>
													<td>SIRET (siège) :</td>
													<td>{element.siren_siret ? (<span>{element.siren_siret.substring(0, 9).replace(/(.{3})/g, "$1 ") + element.siren_siret.substring(9, element.siren_siret.lenght)} <i className="fa-regular fa-clipboard" onClick={() => copyToClipboard(element.siren_siret.substring(0, 9).replace(/(.{3})/g, "$1 ") + element.siren_siret.substring(9, element.siren_siret.lenght))}></i></span>) : ("Non renseigné")}</td>
												</tr>
												<tr>
													<td>Département :</td>
													<td>{element.libelle_departement && element.libelle_region ? (<span>{element.libelle_departement + " (" + element.libelle_region + ")"} <i className="fa-regular fa-clipboard" onClick={() => copyToClipboard(element.libelle_departement + " (" + element.libelle_region + ")")}></i></span>) : ("Non renseigné")}</td>
												</tr>
												<tr>
													<td>Académie :</td>
													<td>{element.libelle_academie ? (<span>{element.libelle_academie} <i className="fa-regular fa-clipboard" onClick={() => copyToClipboard(element.libelle_academie)}></i></span>) : ("Non renseigné")}</td>
												</tr>
											</tbody>
										</table>
										{element.fiche_onisep ? (
											<div className="p-0 mt-5">
												<button className="btn btn-ef text-light"><i className="fa-sharp fa-solid fa-file-arrow-down"></i> <a href={element.fiche_onisep} target="_blank" className="text-decoration-none text-light">Fiche Onisep</a></button>
											</div>
										) : ("")}
									</div>
								</div>
								<div id="infoBox" className="col-md-6 bg">
									<div className="row top-middle p-3">
										<h3 className="mb-3 p-0 fw-bold">Pédagogie de l'établissement</h3>
										<table className="w-100">
											<tbody>
												<tr>
													<td>Type d'établissement :</td>
													<td>{element.type_etablissement ? element.type_etablissement : "Non renseigné"}</td>
												</tr>
												<tr>
													<td>Spécificités :</td>
													<td>
														{element.voie_technologique === "1" ? "Voie technologique, " : ""}
														{element.section_europeenne === "1" ? "Section européenne, " : ""}
														{element.section_internationale === "1" ? "Section internationale, " : ""}
														{element.section_cinema === "1" ? "Section cinéma, " : ""}
														{element.section_theatre === "1" ? "Section théâtre, " : ""}
														{element.section_arts_plastiques === "1" ? "Section arts plastiques, " : ""}
														{element.section_musique_danse === "1" ? "Section musique danse, " : ""}
														{element.section_sport === "1" ? "Section sport, " : ""}
														{element.section_langues_orientales === "1" ? "Section langues orientales, " : ""}
														{element.section_langues_occidentales === "1" ? "Section langues occidentales, " : ""}
														{element.section_langues_rare === "1" ? "Section langues rares, " : ""}
														{element.section_linguistique === "1" ? "Section linguistique, " : ""}
														{element.section_litteraire === "1" ? "Section littéraire, " : ""}
														{element.section_scientifique === "1" ? "Section scientifique, " : ""}
														{element.section_economique === "1" ? "Section économique, " : ""}
														{element.section_commerciale === "1" ? "Section commerciale, " : ""}
														{element.section_administrative === "1" ? "Section administrative, " : ""}
														{element.section_hotellerie === "1" ? "Section hôtellerie, " : ""}
														{element.section_social === "1" ? "Section social, " : ""}
														{element.section_sante === "1" ? "Section santé, " : ""}
														{element.section_transport === "1" ? "Section transport, " : ""}
														{element.section_securite === "1" ? "Section sécurité, " : ""}
														{element.section_industrie === "1" ? "Section industrie, " : ""}
														{element.section_agriculture === "1" ? "Section agriculture, " : ""}
														{element.section_autre === "1" ? "Section autre, " : ""}
														{element.section_enseignement === "1" ? "Section enseignement, " : ""}
														{element.section_recherche === "1" ? "Section recherche, " : ""}
														{element.section_autre === "1" ? "Section autre, " : ""}
													</td>
												</tr>
												<tr>
													<td>Hébergement :</td>
													<td>{element.hebergement ? "Oui" : "Non"}</td>
												</tr>
												<tr>
													<td>Restauration :</td>
													<td>{element.restauration ? "Oui" : "Non"}</td>
												</tr>
												<tr>
													<td>Reconnu établissement numérique :</td>
													<td>{element.etablissement_numerique ? "Oui" : "Non"}</td>
												</tr>
												<tr>
													{element.horaires_classes ?
														<>
															<td className="align-baseline">Ouverture des classes :</td>
															<td>
																<ul>
																	<li>Lundi : <b>{element.horaires_classes.lundi_matin_debut}</b> - <b>{element.horaires_classes.lundi_matin_fin}</b> / <b>{element.horaires_classes.lundi_apres_midi_debut}</b> - <b>{element.horaires_classes.lundi_apres_midi_fin}</b></li>
																	<li>Mardi : <b>{element.horaires_classes.mardi_matin_debut}</b> - <b>{element.horaires_classes.mardi_matin_fin}</b> / <b>{element.horaires_classes.mardi_apres_midi_debut}</b> - <b>{element.horaires_classes.mardi_apres_midi_fin}</b></li>
																	<li>Mercredi : <b>{element.horaires_classes.mercredi_matin_debut}</b> - <b>{element.horaires_classes.mercredi_matin_fin}</b> / <b>{element.horaires_classes.mercredi_apres_midi_debut}</b> - <b>{element.horaires_classes.mercredi_apres_midi_fin}</b></li>
																	<li>Jeudi : <b>{element.horaires_classes.jeudi_matin_debut}</b> - <b>{element.horaires_classes.jeudi_matin_fin}</b> / <b>{element.horaires_classes.mercredi_apres_midi_debut}</b> - <b>{element.horaires_classes.mercredi_apres_midi_fin}</b></li>
																	<li>Vendredi : <b>{element.horaires_classes.vendredi_matin_debut}</b> - <b>{element.horaires_classes.vendredi_matin_fin}</b> / <b>{element.horaires_classes.vendredi_apres_midi_debut}</b> - <b>{element.horaires_classes.vendredi_apres_midi_fin}</b></li>
																</ul>
															</td>
														</>
														: ""}
												</tr>
											</tbody>
										</table>
										{element.horaires_classes === "undefined" ? <p className="text-secondary fw-normal">Aucuns horaires communiquer</p> : ""}
									</div>
								</div>
							</div>
							<div className="row m-5 big-resize flex-column flex-md-row">
								<div id="infoBox" className="col-md-6 bg col-with-margin me-2 mb-3 mb-md-0">
									<div className="row top-middle p-3">
										<h3 className="mb-3 p-0 fw-bold">Informations de contact</h3>
										<table className="w-100">
											<tbody>
												<tr>
													<td>Adresse postale :</td>
													<td>{element.adresse_1 ? (<span>{element.adresse_1.toLowerCase() + ", " + element.code_postal + " " + element.nom_commune[0].toUpperCase() + element.nom_commune.slice(1)} <i className="fa-regular fa-clipboard" onClick={() => copyToClipboard(element.adresse_1.toLowerCase() + ", " + element.code_postal + " " + element.nom_commune[0].toUpperCase() + element.nom_commune.slice(1))}></i></span>) : ("Non renseigné")}</td>
												</tr>
												<tr>
													<td>Email de contact :</td>
													<td>{element.mail ? (<span><a href={"mailto:" + element.mail} className="text-decoration-none text-dark">{element.mail}</a> <i className="fa-regular fa-clipboard" onClick={() => copyToClipboard(element.mail)}></i></span>) : ("Non renseigné")}</td>
												</tr>
												<tr>
													<td>Téléphone :</td>
													<td>{element.telephone ? (<span><a href={"tel:" + element.telephone} className="text-decoration-none text-dark">{element.telephone}</a> <i className="fa-regular fa-clipboard" onClick={() => copyToClipboard(element.telephone)}></i></span>) : ("Non renseigné")}</td>
												</tr>
												<tr>
													<td>Site internet :</td>
													<td>{element.web ? (<span><a href={element.web} target="_blank" rel="noreferrer" className="text-decoration-none text-dark">{element.web}</a> <i className="fa-regular fa-clipboard" onClick={() => copyToClipboard(element.web)}></i></span>) : ("Non renseigné")}</td>
												</tr>
											</tbody>
										</table>
									</div>
								</div>
								<div id="infoBox" className="col-md-6 bg">
									<div className="row top-middle p-3">
										<h3 className="mb-3 p-0 fw-bold">Résultats aux examens</h3>
										<table className="w-100">
											<tbody>
												{!(element.resultats_dnb === "undefined") ?
													<tr>
														<td>Taux de réussite au brevet :</td>
														<td><Chart percentage={parseFloat(element.resultats_dnb[0].fields.taux_de_reussite.replace('%', '').replace(',', '.'))} /> ({element.resultats_dnb[0].fields.session})</td>
													</tr>
													: ""}

												{!(element.resultats_bac === "undefined") ?
													<tr>
														<td>Taux de réussite au baccalauréat :</td>
														<td><Chart percentage={parseFloat(element.resultats_bac[0].fields.taux_brut_de_reussite_total_series.replace('%', '').replace(',', '.'))} /> ({element.resultats_bac[0].fields.annee})</td>
													</tr>
													: ""}
											</tbody>
										</table>
										{element.resultats_dnb === "undefined" && element.resultats_bac === "undefined" ? <p className="text-secondary m-0 p-0">Aucuns résultats aux examens pour cet établissement</p> : ""}
									</div>
								</div>
							</div>
							{!(element.resultats_dnb === "undefined") ?
								<div className="row m-5 big-resize flex-column flex-md-row">
									<div id="infoBox" className="col-md-6 bg col-with-margin me-2 mb-3 mb-md-0">
										<h3 className="my-3 p-0 fw-bold text-center">Évolution du taux de réussite au brevet</h3>
										<ChartDNB element={element} />
									</div>
									<div id="infoBox" className="col-md-6 bg">
										<h3 className="my-3 p-0 fw-bold text-center">Nombre d'élèves par section ayant obtenu leur brevet en {element.resultats_dnb[0].fields.session}</h3>
										<ChartDNBStudent element={element} />
									</div>
								</div>
								: ""}
							{!(element.resultats_bac === "undefined") ?
								<div className="row m-5 big-resize flex-column flex-md-row">
									<div id="infoBox" className="col-md-6 bg col-with-margin me-2 mb-3 mb-md-0">
										<h3 className="my-3 p-0 fw-bold text-center">Évolution du taux de réussite au baccalauréat</h3>
										<ChartBAC element={element} />
									</div>
									<div id="infoBox" className="col-md-6 bg">
										<h3 className="my-3 p-0 fw-bold text-center">Nombre d'élèves par section ayant obtenu leur baccalauréat en {element.resultats_bac[0].fields.annee}</h3>
										<ChartBACStudent element={element} />
									</div>
								</div>
								: ""}
							<div id="bottom-map" className="row bg m-5 big-resize">
								<div className="col-md-12">
									<div className="bottom-card-content">
										<Map element={element} />
									</div>
								</div>
							</div>
							<div id="bottom-map" className="row bg m-5 big-resize">
								<div className="col-md-12">
									<div className="bottom-card-content">
										<h3 className="mb-3 p-0 fw-bold">Les outils recommandés</h3>
										<div className="row">
											<div className="col-4">
												<a className="text-decoration-none text-black" href="https://ecole-futee.com" data-wpel-link="external" target="_blank" rel="external noopener noreferrer">
													<p>Gestion de l'établissement</p>
													<img width="190" height="50" src="https://ecole-futee.com/wp-content/uploads/elementor/thumbs/logo-web-transparent-q5a6vwo7c93i3dnk12sirpz8mod2kpnli7owgwexa8.png" className="vertical-align-middle d-inline-block" alt="Logo EBP" loading="lazy" />
												</a>
											</div>
											<div className="col-4">
												<a className="text-decoration-none text-black" href="https://www.ebp.com/logiciel-comptabilite" data-wpel-link="external" target="_blank" rel="external noopener noreferrer">
													<p>Comptabilité de l'établissement</p>
													<img width="110" height="50" src="https://www.ebp.com/wp-content/uploads/2024/01/Logo-EBP-2024-RVB.svg" className="vertical-align-middle d-inline-block" alt="Logo EBP" loading="lazy" />
												</a>
											</div>
											<div className="col-4">
												<a className="text-decoration-none text-black" href="https://www.fidemconseil.com/" data-wpel-link="external" target="_blank" rel="external noopener noreferrer">
													<p>Société de conseil</p>
													<img width="110" height="85" src="https://static.wixstatic.com/media/bcb97f_f203ec7f515d426f9a5cad8a9f930ab3.png/v1/fill/w_478,h_380,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/bcb97f_f203ec7f515d426f9a5cad8a9f930ab3.png" className="vertical-align-middle d-inline-block" alt="Logo EBP" loading="lazy" />
												</a>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="card-container foot">
							<div className="d-flex flex-column">
								<a href="#" rel="noreferrer" className="mx-auto"><i className="fa-solid fa-arrow-up fa-2xl text-dark"></i></a>
							</div>
						</div>
					</>
				</div>
			}
		</div>
	);
}

function Routing({ searchQuery, setSearchQuery, data, setData }) {
	return (
		<BrowserRouter>
			<Routes>
				<Route path="/" element={<Layout />}>
					<Route index element={<Home setSearchQuery={setSearchQuery} />} />
					<Route path="recherche" element={<Search searchQuery={searchQuery} setSearchQuery={setSearchQuery} data={data} setData={setData} />} />
					<Route path="contact" element={<Contact />} />
					<Route path="apropos" element={<Apropos />} />
					<Route exact path="/etablissements/:id" element={<Element searchQuery={searchQuery} setSearchQuery={setSearchQuery} data={data} />} />
					<Route path="*" element={<Error />} />
				</Route>
			</Routes>
		</BrowserRouter>
	);
}

function App() {
	const [searchQuery, setSearchQuery] = useState(["", ""]);
	const [data, setData] = useState([]);

	return (
		<>
			<Routing searchQuery={searchQuery}
				setSearchQuery={setSearchQuery}
				data={data}
				setData={setData} />
		</>
	);
}

export default App;