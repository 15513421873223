import { Link } from "react-router-dom";
import { useParams, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import slugify from 'slugify';
import SearchBarSearchPage from "./SearchBar";
import "./SearchCard.css"
import "./Search.css"
import ApiService from '../../services/Api';
import Loader from '../Utils/Loader';

export const SearchBarHome = ({ setSearchQuery }) => {
    const navigate = useNavigate();
    const HandleSearchSubmit = (event) => {
        event.preventDefault();
        const searchQuery = event.target.elements.searchInput.value;
        navigate(`/recherche?query=${searchQuery}`);
        setSearchQuery(searchQuery);
    };

    return (
        <>
            <form onSubmit={HandleSearchSubmit}>
                <div className="row justify-content-center">
                    <div className="col-xl-6 mt-2">
                        <input type="text" name="searchInput" placeholder="Ville de l'établissement, nom, type, code uai..." className="form-control form-control-lg small-placeholder" />
                    </div>
                    <div className="col-auto mt-2">
                        <button type="submit" className="btn btn-ef btn-lg">Rechercher</button>
                    </div>
                </div>
            </form>
        </>
    )
}

function CreateUrlElementTitle({ record }) {
    const id = record.fields.identifiant_de_l_etablissement;
    const str = record.fields.nom_etablissement;
    const slug = slugify(str, { lower: true, strict: true });

    return (
        <Link className="titre text-white text-capitalize text-decoration-none" to={`/etablissements/${slug}-${id}&advanced=0`}>{record.fields.nom_etablissement}</Link>
    )
}

function CreateUrlElement({ record }) {
    const id = record.fields.identifiant_de_l_etablissement;
    const str = record.fields.nom_etablissement;
    const slug = slugify(str, { lower: true, strict: true });
    // const icon = '>';

    return (
        <Link className="btn btn-ef container-resultat-button rounded-ef" to={`/etablissements/${slug}-${id}&advanced=0`}><i className="fa-solid fa-arrow-right"></i></Link>
    )
}

function formatDateFr(dateBadFormat) {
    const date = new Date(dateBadFormat);
    const dateFormatted = date.toLocaleDateString("fr-FR");

    return dateFormatted;
}

function ElementList({ searchQuery, data, totalItems, isLoading, nhits, currentPage, setCurrentPage }) {
    const itemsPerPage = new ApiService().itemsPerPage;
    const totalPages = Math.ceil(totalItems / itemsPerPage);
    const pageNumbers = [];

    for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
    }

    const handlePageClick = (event, pageNumber) => {
        event.preventDefault();
        setCurrentPage(pageNumber);
    };

    return (
        <div>

            {isLoading ? <Loader isLoading={isLoading} /> :
                <>
                    <div className="text-center">
                        {/* <h3>Résultats pour "{searchQuery}":</h3> */}
                        {nhits === 1 ?
                            <h5>{nhits} établissement correspond à votre requête</h5> :
                            <h5 className="text-secondary">{nhits} établissements correspondent à votre requête dans {totalPages} pages</h5>
                        }
                    </div>
                    <div className="container pagination">
                        <nav>
                            <ul className="pagination">
                                {currentPage !== 1 && (
                                    <li className="page-item"><a className="page-link" href="#" onClick={() => setCurrentPage(currentPage - 1)}>Précédent</a></li>
                                )}
                                {pageNumbers.map((pageNumber) => (
                                    (pageNumber >= currentPage - 2 && pageNumber <= currentPage + 2 && totalPages > 1) && (
                                        <li key={pageNumber}
                                            className={`page-item ${currentPage === pageNumber ? "active" : null}`}
                                        >
                                            <a href="!#" className="page-link" onClick={(event) => handlePageClick(event, pageNumber)}>
                                                {pageNumber}
                                            </a>
                                        </li>
                                    )
                                ))}
                                {currentPage < totalPages && (
                                    <li className="page-item"><a className="page-link" href="#" onClick={() => setCurrentPage(currentPage + 1)}>Suivant</a></li>
                                )}
                            </ul>
                        </nav>
                    </div>
                    <div className="search_results">
                        <div className="search_result">
                            <div className="container">
                                {data.map((record) => (
                                    <div key={record.recordid} className="container-resultat">
                                        <div className="nom-ecole">
                                            <h4><CreateUrlElementTitle record={record} /></h4>
                                        </div>
                                        <div className="fiche-ecole row">
                                            <div className="content col-6">
                                                <p>Statut</p>
                                                <p className="fw-bold color-black">{record.fields.statut_public_prive ? record.fields.statut_public_prive : "Non renseigné"}</p>
                                                <p>Depuis le {record.fields.date_ouverture ? formatDateFr(record.fields.date_ouverture) : "Non renseigné"}</p>
                                            </div>
                                            <div className="content col-6">
                                                <p>Type</p>
                                                <p className="fw-bold color-black">{record.fields.type_etablissement ? record.fields.type_etablissement : "Non renseigné"}</p>
                                                <p>Code UAI : {record.fields.identifiant_de_l_etablissement ? record.fields.identifiant_de_l_etablissement : "Non renseigné"}</p>
                                            </div>
                                            <div className="content col-6 pt-3">
                                                <p>Lieu</p>
                                                <p className="text-uppercase fw-bold color-black">{record.fields.nom_commune ? record.fields.nom_commune : "Non renseigné"}</p>
                                                <p>Code postal : {record.fields.code_postal ? record.fields.code_postal : "Non renseigné"}</p>
                                            </div>
                                            <div className="content col-6 pt-3">
                                                <p>Effectif</p>
                                                <p className="fw-bold color-black">{record.fields.nombre_d_eleves ? record.fields.nombre_d_eleves + " élèves" : "Non renseigné"}</p>
                                            </div>
                                        </div>
                                        <CreateUrlElement record={record} />
                                        <hr className="w-100"></hr>
                                        <div className="container-resultat2">
                                            <p className="orange fw-bold"><i className="fa-solid fa-arrow-right"></i> Informations de contacts</p>
                                            <div className="fiche-ecole pt-0 row">
                                                <div className="content col-6">
                                                    <p>Téléphone</p>
                                                    <p className="fw-bold color-black">{record.fields.telephone ? <><i className="fa-solid fa-phone"></i> <a href={"tel:" + record.fields.telephone.replaceAll(' ', '').replace(/(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})/, '$1 $2 $3 $4 $5')} className="text-decoration-none text-dark">{record.fields.telephone.replaceAll(' ', '').replace(/(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})/, '$1 $2 $3 $4 $5')}</a></> : "Non renseigné"}</p>
                                                </div>
                                                <div className="content col-6">
                                                    <p>Email</p>
                                                    <p className="fw-bold color-black">{record.fields.mail ? <><i className="fa-solid fa-envelope"></i> <a href={"mailto:" + record.fields.mail} className="text-decoration-none text-dark">{record.fields.mail}</a></> : "Non renseigné"}</p>
                                                </div>
                                                <div className="content col-12">
                                                    <p>Adresse</p>
                                                    <p className="fw-bold color-black">{record.fields.adresse_1 && record.fields.code_postal && record.fields.nom_commune ? record.fields.adresse_1.toLowerCase() + ", " + record.fields.code_postal + " " + record.fields.nom_commune.toUpperCase() : "Non renseigné"}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className="container pagination">
                        <nav>
                            <ul className="pagination">
                                {currentPage !== 1 && (
                                    <li className="page-item"><a className="page-link" href="#" onClick={() => setCurrentPage(currentPage - 1)}>Précédent</a></li>
                                )}
                                {pageNumbers.map((pageNumber) => (
                                    (pageNumber >= currentPage - 2 && pageNumber <= currentPage + 2 && totalPages > 1) && (
                                        <li key={pageNumber}
                                            className={`page-item ${currentPage === pageNumber ? "active" : null}`}
                                        >
                                            <a href="!#" className="page-link" onClick={(event) => handlePageClick(event, pageNumber)}>
                                                {pageNumber}
                                            </a>
                                        </li>
                                    )
                                ))}
                                {currentPage < totalPages && (
                                    <li className="page-item"><a className="page-link" href="#" onClick={() => setCurrentPage(currentPage + 1)}>Suivant</a></li>
                                )}
                            </ul>
                        </nav>
                    </div>
                </>
            }
        </div>
    );
}

function HandleSearchResult({ searchQuery, setSearchQuery, data, setData }) {
    const [isLoading, setIsLoading] = useState(true);
    const [nhits, setNhits] = useState(0);
    const [totalItems, setTotalItems] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);

    useEffect(() => {
        const api = new ApiService();
        setIsLoading(true);
        api.getData(searchQuery, currentPage).then(async data => {
            setNhits(data[0]);
            setTotalItems(data[0]);
            await setData(data[1]);
            setIsLoading(false);
        });
    }, [searchQuery, currentPage]);

    return (
        <>
            <ElementList searchQuery={searchQuery}
                data={data} setData={setData}
                totalItems={totalItems}
                isLoading={isLoading}
                nhits={nhits}
                currentPage={currentPage} setCurrentPage={setCurrentPage} />
        </>
    );
}

const Search = ({ searchQuery, setSearchQuery, data, setData }) => {

    useEffect(() => {
        document.title = "Résultats pour \"" + searchQuery + "\"";
    }, [searchQuery]);

    return (
        <>
            <SearchBarSearchPage setSearchQuery={setSearchQuery} data={data} />
            <HandleSearchResult searchQuery={searchQuery}
                setSearchQuery={setSearchQuery}
                data={data}
                setData={setData} />
        </>
    );
};

export default Search;
