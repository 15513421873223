import React from 'react';
import { Link } from "react-router-dom";
import { MapContainer, TileLayer, Marker, Popup, useMapEvents } from 'react-leaflet';
import MarkerClusterGroup from 'react-leaflet-cluster';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import { useEffect, useState, useRef } from "react";
import slugify from 'slugify';
import "./Map.css";
import ApiService from '../../services/Api';

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

function CreateMapUrlElement({ record }) {
    const id = record.fields.identifiant_de_l_etablissement;
    const str = record.fields.nom_etablissement;
    const slug = slugify(str, { lower: true, strict: true });
    const icon = '>';

    return (
        <Link to={`/etablissements/${slug}-${id}`}>Voir la fiche école</Link>
    )
}


function MapEvents({ setBounds }) {
    const map = useMapEvents({
        moveend() {
            const minlat = map.getBounds().getSouthWest().lat;
            const minlng = map.getBounds().getSouthWest().lng;
            const maxlat = map.getBounds().getNorthEast().lat;
            const maxlng = map.getBounds().getNorthEast().lng;
            setBounds([minlat, maxlat, minlng, maxlng]);
        }
    });
    map.on('load', () => {
        const minlat = map.getBounds().getSouthWest().lat;
        const minlng = map.getBounds().getSouthWest().lng;
        const maxlat = map.getBounds().getNorthEast().lat;
        const maxlng = map.getBounds().getNorthEast().lng;
        setBounds([minlat, maxlat, minlng, maxlng]);
    });
}

function Map({ element }) {
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [bounds, setBounds] = useState([0, 0, 0, 0]);
    var center = [47.081012, 2.398782];
    var zoom = 4;

    if (element && element.latitude && element.longitude) {
        var latitude = element.latitude;
        var longitude = element.longitude;
        center = [latitude, longitude];
        zoom = 16;
    } else {
        latitude = null;
        longitude = null;
    }

    useEffect(() => {
    }, []);

    useEffect(() => {
        setIsLoading(true);
        var urlApi = "https://data.education.gouv.fr/api/records/1.0/search/?dataset=fr-en-annuaire-education"
            + "&q=latitude>" + ("" + bounds[0])
            + "&q=latitude<" + ("" + bounds[1])
            + "&q=longitude>" + ("" + bounds[2])
            + "&q=longitude<" + ("" + bounds[3])
            + "&rows=500";
        fetch(urlApi)
            .then(response => response.json())
            .then(data => {
                setData(data.records);
                setIsLoading(false);
            });
    }, [bounds]);
    
    // const AllData = [];

    // const Api = new ApiService();
    // Api.getNhits('').then((data) => {
    //     for(var i = 0; i < data; i++) {
    //         AllData.push(center, i);
    //     }
    // });

    return (
        <>
            <MapContainer center={center} zoom={zoom} style={{ height: '600px' }}>
                <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                <MapEvents setBounds={setBounds} />
                {(element & latitude & longitude) && (
                    <Marker key={element.recordid} position={[latitude, longitude]}>
                        <Popup>
                            <b>{element.nom_etablissement}</b> <br />
                            {/* <CreateMapUrlElement record={record}/> */}
                        </Popup>
                    </Marker>
                )}
                <MarkerClusterGroup>
                    {/* {AllData.map((record) => (
                        <Marker key={record[1]} position={record[0]}>
                        </Marker>
                    ))} */}
                    {data.map((record) => (
                        <Marker key={record.recordid} position={[record.geometry.coordinates[1], record.geometry.coordinates[0]]}>
                            <Popup>
                                <b>{record.fields.nom_etablissement}</b> <br />
                                <CreateMapUrlElement record={record} />
                            </Popup>
                        </Marker>
                    ))}
                </MarkerClusterGroup>
            </MapContainer>
        </>
    );
}

export default Map;

