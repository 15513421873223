import { useState } from "react";
import { useNavigate } from 'react-router-dom';
import "./SearchBar.css"

export const SearchBarSearchPage = ({ searchQueryTitle, searchQuery, setSearchQuery, data }) => {
    const navigate = useNavigate();
    const [statut, setStatut] = useState('');
    const [type, setType] = useState('');
    const [selectedType, setSelectedType] = useState('');
    const [inputValue, setInputValue] = useState("value");
    var searchQueryInput = "";
    const HandleSearchSubmit = (event) => {
        event.preventDefault();
        // var query = event.target.elements.searchInput.value;
        var query = document.getElementById("input-searchbar").value;
        var searchQueryTitle = query + ".";
        // var query = event.target.value;
        // setSearchQuery = event.target.elements.searchInput.value;
        // var query = "";
        var commune = document.getElementById("filter-commune").value;
        // var statut = document.getElementById("filter-statut").value;
        // var niveau = document.getElementById("filter-niveau").value;
        // if (searchQueryInput === "") {
        //     searchQueryInput = searchQuery;
        // }
        if (commune && commune !== "") {
            query += "&q=nom_commune=" + commune;
        }
        // if (statut === 'aucune')
        if (statut === 'public')
            query += '&q=statut_public_prive=public';
        if (statut === 'prive')
            query += '&q=statut_public_prive=prive';
        if (statut === 'hors_contrat')
            query += '&q=statut_public_prive=prive&q=type_contrat_prive=hors contrat';
        if (type === 'college')
            query += '&q=type_etablissement=college';
        if (type === 'ecole')
            query += '&q=type_etablissement=ecole';
        if (type === 'erea')
            query += '&q=type_etablissement=erea';
        if (type === 'info')
            query += '&q=type_etablissement=information et orientation';
        if (type === 'lycee')
            query += '&q=type_etablissement=lycee';
        if (type === 'medico')
            query += '&q=type_etablissement=medico-social';
        if (type === 'admin')
            query += '&q=type_etablissement=service administratif';
        // if (statut && statut !== "") {
        //     query += "&q=statut_public_prive=" + statut;
        // }
        // if (niveau && niveau !== "") {
        //     query += "&q=libelle_nature=" + niveau;
        // }
        navigate("/recherche?query=" + query);
        setSearchQuery(searchQueryTitle);
    };

    if (data !== undefined) {


        const HandleTypeButton = (type) => {
            setType(type);
        }

        const HandleStatutButton = (statut) => {
            setStatut(statut);
            // var query = "";
            // if (statut === 'public') {
            //     query = '&q=statut_public_prive=public';
            // }
            // if (statut === 'prive') {
            //     query = '&q=statut_public_prive=prive';
            //     console.log('Statut privé sélectionné');
            // }
            // if (statut === 'hors_contrat')
            //     query = '&q=statut_public_prive=prive&q=type_contrat_prive=hors contrat'
            // navigate("/recherche?query=" + searchQueryInput + " " + query);
            // setSearchQuery(searchQueryInput + " " + query);
        };

        // const handleSelectTypeChange = (event) => {
        //     const value = event.target.value;
        //     setSelectedType(value);
        //     console.log(value);
        // };

        const handleChange = (event) => {
            setInputValue(event.target.value);
        };

        return (
            <>
                <form onSubmit={HandleSearchSubmit} className="divsearchbar">
                    <div className="row searchbar">
                        <div className="input-group">
                            {/* <input id="input-searchbar" onChange={handleChange} type="text" name="searchInput" placeholder="Ville de l'établissement, nom, type, code UAI..." className="form-control form-control-lg small-placeholder" /> */}
                            <input id="input-searchbar" onChange={handleChange} type="text" name="searchInput" placeholder="Ville de l'établissement, nom, type, code UAI..." className="form-control form-control-lg small-placeholder" />
                            <button id="search-btn" type="submit" className="btn btn-ef btn-lg text-light">Rechercher</button>
                        </div>
                    </div>
                </form>

                {/* <div className="divsearchbar">
                    <div className="row searchbar">
                        <div className="input-group">
                            <input id="input-searchbar" value={inputValue} onChange={HandleSearchSubmit} type="text" name="searchInput" placeholder="Ville de l'établissement, nom, type, code UAI..." className="form-control form-control-lg small-placeholder" />
                            <button id="search-btn" type="submit" className="btn btn-ef btn-lg text-light">Rechercher</button>
                        </div>
                    </div>
                </div> */}
                <div className="container">
                    <div className="flex row justify-content-center">
                        <div className="col-lg-8">
                            <div id="search-btns" className="mb-3 d-flex">
                                <button className="btn btn-ef mx-auto" data-bs-toggle="collapse" data-bs-target="#collapse-commune" aria-expanded={getURLParam('advanced') === '1' ? "true" : "false"} aria-controls="collapse-commune">Nom de la commune</button>
                                <button className="btn btn-ef mx-auto" data-bs-toggle="collapse" data-bs-target="#collapse-statut" aria-expanded="false" aria-controls="collapse-statut">Statut</button>
                                <button className="btn btn-ef mx-auto" data-bs-toggle="collapse" data-bs-target="#collapse-niveau" aria-expanded="false" aria-controls="collapse-niveau">Type</button>
                            </div>
                            <div className={getURLParam('advanced') === '1' ? "collapse show" : "collapse"} id="collapse-commune">
                                <div className="card card-body">
                                    <div className="row my-1">
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <input type="text" className="form-control" id="filter-commune" placeholder="Commune..." />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="collapse" id="collapse-statut">
                                <div className="card card-body">
                                    <div className="row my-1">
                                        <div className="col-md-12">
                                            <button className={`btn mx-1 my-1 ${statut === 'aucun' ? 'btn-selected' : 'btn-unselected'}`} onClick={() => HandleStatutButton('aucun')}>Aucun</button>
                                            <button className={`btn mx-1 my-1 ${statut === 'hors_contrat' ? 'btn-selected' : 'btn-unselected'}`} onClick={() => HandleStatutButton('hors_contrat')}>Hors Contrat</button>
                                            <button className={`btn mx-1 my-1 ${statut === 'prive' ? 'btn-selected' : 'btn-unselected'}`} onClick={() => HandleStatutButton('prive')}>Privé</button>
                                            <button className={`btn mx-1 my-1 ${statut === 'public' ? 'btn-selected' : 'btn-unselected'}`} onClick={() => HandleStatutButton('public')}>Public</button>
                                            {/* <button className={`btn mx-1 my-1 ${statut === 'autre' ? 'btn-selected' : 'btn-unselected'}`} onClick={() => HandleStatutButton ('autre')}>Autre</button>
                                            {statut === 'autre' && <input type="text" className="form-control my-1" id="filter-statut" placeholder="Public, privé..." />} */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="collapse" id="collapse-niveau">
                                <div className="card card-body">
                                    <div className="row my-1">
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                {/* <select className="form-select" aria-label="select filter type" onChange={handleSelectTypeChange}>
                                                    <option selected>Sélectionnez le type d'établissement</option>
                                                    <option value="autre">Autre</option>
                                                    <option value="college">Collège</option>
                                                    <option value="ecole">Ecole</option>
                                                    <option value="erea">EREA</option>
                                                    <option value="info">Information et orientation</option>
                                                    <option value="lycee">Lycée</option>
                                                    <option value="medico">Médico-social</option>
                                                    <option value="admin">Service administratif</option>
                                                </select> */}
                                                <button className={`btn mx-1 my-1 ${type === 'aucun' ? 'btn-selected' : 'btn-unselected'}`} onClick={() => HandleTypeButton('aucun')}>Aucun</button>
                                                <button className={`btn mx-1 my-1 ${type === 'college' ? 'btn-selected' : 'btn-unselected'}`} onClick={() => HandleTypeButton('college')}>Collège</button>
                                                <button className={`btn mx-1 my-1 ${type === 'ecole' ? 'btn-selected' : 'btn-unselected'}`} onClick={() => HandleTypeButton('ecole')}>Ecole</button>
                                                <button className={`btn mx-1 my-1 ${type === 'erea' ? 'btn-selected' : 'btn-unselected'}`} onClick={() => HandleTypeButton('erea')}>EREA</button>
                                                <button className={`btn mx-1 my-1 ${type === 'info' ? 'btn-selected' : 'btn-unselected'}`} onClick={() => HandleTypeButton('info')}>Information et orientation</button>
                                                <button className={`btn mx-1 my-1 ${type === 'lycee' ? 'btn-selected' : 'btn-unselected'}`} onClick={() => HandleTypeButton('lycee')}>Lycée</button>
                                                <button className={`btn mx-1 my-1 ${type === 'medico' ? 'btn-selected' : 'btn-unselected'}`} onClick={() => HandleTypeButton('medico')}>Médico-social</button>
                                                <button className={`btn mx-1 my-1 ${type === 'admin' ? 'btn-selected' : 'btn-unselected'}`} onClick={() => HandleTypeButton('admin')}>Service administratif</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="apply-filter justify-content-center">
                            <button className="btn btn-ef btn-sm text-light orange" onClick={HandleSearchSubmit}>Appliquer</button>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

const urlParams = new URLSearchParams(window.location.search);

function getURLParam(param) {
    return urlParams.get(param) || '0';
}

export default SearchBarSearchPage;