const Error = () => {
	return (
		<>
			<h1 className="text-center">Oups, la page que vous essayez de consulter n'existe pas...</h1>
			<div className="d-flex">
				<img src="/404.png" alt="Erreur 404" className="img-fluid mx-auto" />
			</div>
			<p className="text-center"><a href="/" className="text-decoration-none text-dark">Revenir à l'accueil</a></p>
		</>
	);
};

export default Error;
