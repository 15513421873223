import { URL_API, URL_API_SPORT, URL_API_RESULTS_DNB, URL_API_RESULTS_BAC, URL_API_SCHEDULE } from "./Const"
class ApiService {
    constructor() {
        this.data = [];
        this.global_data = [];
        this.isLoading = true;
        this.nhits = 0;
        this.currentPage = 1;
        this.itemsPerPage = 50;
        this.totalItems = 0;
        this.digitId = "undefined";
    }

    async getData(searchQuery, currentPage) {
        var urlApi = 'https://data.education.gouv.fr/api/records/1.0/search/?dataset=fr-en-annuaire-education&q='
            + searchQuery + '&rows=' + this.itemsPerPage + '&start=' + ((currentPage - 1) * this.itemsPerPage);
        // const urlApi = `https://data.education.gouv.fr/api/records/1.0/search/?dataset=fr-en-annuaire-education&q=${searchQuery}&rows=${this.itemsPerPage}&start=${(currentPage - 1) * this.itemsPerPage}`;

        try {
            const response = await fetch(urlApi);
            const data = await response.json();
            this.nhits = data.nhits;
            // console.log(this.nhits);
            this.data = data.records;
            this.global_data = data.records;
            this.isLoading = false;
            this.totalItems = data.nhits;
        } catch (error) {
            console.error(error);
        }

        return [this.nhits, this.data];
    }

    // async getGlobalData() {
    //     var urlApi = 'https://data.education.gouv.fr/api/records/1.0/search/?dataset=fr-en-annuaire-education&q=&rows=10000';
    //     // const urlApi = `https://data.education.gouv.fr/api/records/1.0/search/?dataset=fr-en-annuaire-education&q=${searchQuery}&rows=${this.itemsPerPage}&start=${(currentPage - 1) * this.itemsPerPage}`;

    //     try {
    //         const response = await fetch(urlApi);
    //         const data = await response.json();
    //         this.nhits = data.nhits;
    //         // console.log(this.nhits);
    //         this.data = data.records;
    //         this.isLoading = false;
    //         this.totalItems = data.nhits;
    //     } catch (error) {
    //         console.error(error);
    //     }

    //     return [this.data];
    // }

    formatDateFr(dateBadFormat) {
        const date = new Date(dateBadFormat);
        return date.toLocaleDateString("fr-FR");
    }

    formatPhoneNumber(numberBadFormat) {
        return numberBadFormat.replaceAll(' ', '').replace(/(.{2})/g, '$1 ');
    }

    async getApiData() {
        const urlApi = URL_API + '&q=identifiant_de_l_etablissement=' + this.digitId;
        const responseApi = await fetch(urlApi);
        const dataApi = await responseApi.json();
        this.data = dataApi.records[0].fields;
        this.data.record_timestamp = this.formatDateFr(dataApi.records[0].record_timestamp);
        this.data.date_ouverture = this.formatDateFr(this.data.date_ouverture);
        if (this.data.telephone)
            this.data.telephone = this.formatPhoneNumber(this.data.telephone);
        if (this.data.etat === "A FERMER")
            this.data.etat = "À FERMER";
    }

    async getApiDataSport() {
        const urlApiSport = URL_API_SPORT + "&q=uai=" + this.digitId;
        const responseApiSport = await fetch(urlApiSport);
        const dataUrlSport = await responseApiSport.json();
        if (dataUrlSport.nhits !== 0)
            this.data.daily_sport = dataUrlSport.records[0].fields.moments_journee_30_min_activite_physique;
    }

    async getApiDataResults() {
        const urlApiResultsDnb = URL_API_RESULTS_DNB + "&q=numero_d_etablissement=" + this.digitId + "&sort=-session" + "&rows=30";
        const urlApiResultsBac = URL_API_RESULTS_BAC + "&q=" + this.digitId + "&sort=-annee&rows=30";
        const responseApiResultsDnb = await fetch(urlApiResultsDnb);
        const responseApiResultsBac = await fetch(urlApiResultsBac);
        const dataUrlApiResultsDnb = await responseApiResultsDnb.json();
        const dataUrlApiResultsBac = await responseApiResultsBac.json();

        this.data.resultats_dnb = dataUrlApiResultsDnb.records;
        if (this.data.resultats_dnb.length === 0)
            this.data.resultats_dnb = "undefined";
        this.data.resultats_bac = dataUrlApiResultsBac.records;
        if (this.data.resultats_bac.length === 0)
            this.data.resultats_bac = "undefined";
    }

    async getApiDataSchedule() {
        const urlApiSchedule = URL_API_SCHEDULE + "&q=" + this.digitId;
        const responseApiSchedule = await fetch(urlApiSchedule);
        const dataUrlApiResultsSchedule = await responseApiSchedule.json();

        if (dataUrlApiResultsSchedule.nhits !== 0)
            this.data.horaires_classes = dataUrlApiResultsSchedule.records[0].fields;
        // console.log(this.data.horaires_classes);
    }

    async getFullData(id) {
        this.digitId = id.split('-').pop();
        try {
            await this.getApiData();
            await this.getApiDataSport();
            await this.getApiDataResults();
            await this.getApiDataSchedule();

        } catch (error) {
            console.error(error);
        }

        return this.data;
    }
}

export default ApiService;
