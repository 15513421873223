import React, { useEffect, useRef, useState } from 'react';
import { Modal } from 'react-bootstrap';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

// chart: {
//     type: 'bar',
//     height: 120
//   },  
//   title: {
//     text: '',
//     align: 'center',
//     margin: 0
//   },
//   subtitle: {
//     text: 'Taux de réussite au brevet',
//     verticalAlign: "bottom",
//     /* y: 10 */
//   },
//   credits: false,
//   legend: false,
//   tooltip: false,
//   plotOptions: {
//     bar: {
//       /* stacking: 'normal', */
//       borderWidth: 0,
//       borderRadius: 3
//     }
//   },
//   xAxis: {
//     visible: false
//   },
//   yAxis: {
//     visible: true,
//     min: 0,
//     max: 100,
//     title: {
//       text: null
//     },
//     gridLineWidth: 0,
//     labels: {
//       y: -2
//     }
//   },
//   series: [
//    {
//     name: "Fill",
//     data: [100],
//     color: "gray",
//     grouping: false
//     // enableMouseTracking: false, disable tooltip on just this data element
//   },
//   {
//     name: "Percentage",
//     data: [75.8],
//     color: "orange",
//     dataLabels: {
//       enabled: true,
//       inside: true,
//       align: 'right',
//       format: '{point.y}%',
//       style: {
//         color: 'white',
//         textOutline: false,
//       }
//     }
//   }
//   ]
// })

const Chart = ({ percentage }) => {
    const options = {
        chart: {
            type: 'bar',
            height: 120
        },
        title: {
            text: '',
            align: 'center',
            margin: 0
        },
        subtitle: {
            text: 'Taux de réussite au brevet',
            verticalAlign: "bottom",
        },
        credits: false,
        legend: false,
        tooltip: false,
        plotOptions: {
            bar: {
                borderWidth: 0,
                borderRadius: 3
            }
        },
        xAxis: {
            visible: false
        },
        yAxis: {
            visible: true,
            min: 0,
            max: 100,
            title: {
                text: null
            },
            gridLineWidth: 0,
            labels: {
                y: -2
            }
        },
        series: [
            {
                name: "Fill",
                data: [100],
                color: "blue",
                grouping: false
                // enableMouseTracking: false, disable tooltip on just this data element
            },
            {
                name: "Percentage",
                data: [percentage],
                color: "orange",
                dataLabels: {
                    enabled: true,
                    inside: true,
                    align: 'right',
                    format: '{point.y}%',
                    style: {
                        color: 'white',
                        textOutline: false,
                    }
                }
            }
        ]
    };

    return (
        <HighchartsReact highcharts={Highcharts} options={options} />
    );
};

function BacResults({ element }) {
    // if (element.resultats_bac === "Non renseigné")
    //     return <p>Non renseigné</p>;

    return (
        <>
            {element.resultats_bac === "undefined" ? <p>Pas d'examens disponibles pour cet établissement</p> :
                <>
                    <h5>Diplôme National du Baccalauréat</h5>
                    <br />

                    <p><b>Région: </b>{element.resultats_bac[0].fields.libelle_region_2016}</p>
                    <p><b>Département: </b>{element.resultats_bac[0].fields.departement}</p>
                    <p><b>Ville: </b>{element.resultats_bac[0].fields.commune} {element.resultats_bac[0].fields.ville}</p>
                    <p><b>Etablissement: </b>{element.resultats_bac[0].fields.etablissement}</p>
                    <p><b>Académie: </b>{element.resultats_bac[0].fields.academie}</p>

                    <div className="accordion" id="accordionResults">
                        {element.resultats_bac.map((record) => (
                            <div className="accordion-item">
                                <h2 className="accordion-header" id={`heading${record.recordid}`}>
                                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target={`#collapse${record.recordid}`} aria-expanded="true" aria-controls={`collapse${record.recordid}`} data-bs-parent="">
                                        Session {record.fields.annee}
                                    </button>
                                </h2>
                                <div id={`collapse${record.recordid}`} className="accordion-collapse collapse" aria-labelledby={`heading${record.recordid}`} data-bs-parent="#accordionResults">
                                    <div className="accordion-body">
                                        <p>{record.fields.effectif_presents_total_series ? "Effectif présents toutes séries: " + record.fields.effectif_presents_total_series : ""}</p>
                                        <p>{record.fields.taux_brut_de_reussite_total_series ? "Taux réussite toutes séries: " + record.fields.taux_mention_brut_toutes_series + "%" : ""}</p>
                                        <p>{record.fields.taux_brut_de_reussite_serie_s ? "Taux réussite série S: " + record.fields.taux_brut_de_reussite_serie_s + "%" : ""}</p>
                                        <p>{record.fields.taux_brut_de_reussite_serie_l ? "Taux réussite série L: " + record.fields.taux_brut_de_reussite_serie_l + "%" : ""}</p>
                                        <p>{record.fields.taux_brut_de_reussite_serie_es ? "Taux réussite série ES: " + record.fields.taux_brut_de_reussite_serie_es + "%" : ""}</p>
                                        <p>{record.fields.taux_brut_de_reussite_serie_stmg ? "Taux réussite série STMG: " + record.fields.taux_brut_de_reussite_serie_stmg + "%" : ""}</p>
                                        <p>{record.fields.taux_brut_de_reussite_serie_sti2d ? "Taux réussite STI2D: " + record.fields.taux_brut_de_reussite_serie_sti2d + "%" : ""}</p>
                                        <p>{record.fields.taux_brut_de_reussite_serie_std2a ? "Taux réussite STI2D: " + record.fields.taux_brut_de_reussite_serie_std2a + "%" : ""}</p>
                                        <p>{record.fields.taux_brut_de_reussite_serie_stg ? "Taux réussite STI2D: " + record.fields.taux_brut_de_reussite_serie_stg + "%" : ""}</p>
                                        <p>{record.fields.taux_mention_brut_toutes_series ? "Taux mention toutes séries: " + record.fields.taux_mention_brut_toutes_series + "%" : ""}</p>
                                        <p>{record.fields.taux_mention_brut_serie_s ? "Taux mention série S: " + record.fields.taux_mention_brut_serie_s + "%" : ""}</p>
                                        <p>{record.fields.taux_mention_brut_serie_l ? "Taux mention série L: " + record.fields.taux_mention_brut_serie_l + "%" : ""}</p>
                                        <p>{record.fields.taux_mention_brut_serie_es ? "Taux mention série ES: " + record.fields.effectif_presents_serie_es + "%" : ""}</p>
                                        <p>{record.fields.taux_mention_brut_serie_stmg ? "Taux mention série STMG: " + record.fields.taux_mention_brut_serie_stmg + "%" : ""}</p>
                                        <p>{record.fields.taux_mention_brut_serie_sti2d ? "Taux mention STI2D: " + record.fields.taux_reussite_attendu_france_serie_sti2d + "%" : ""}</p>
                                        <p>{record.fields.taux_mention_brut_serie_std2a ? "Taux mention STI2D: " + record.fields.taux_brut_de_reussite_serie_std2a + "%" : ""}</p>
                                        <p>{record.fields.taux_mention_brut_serie_stg ? "Taux mention STI2D: " + record.fields.taux_brut_de_reussite_serie_stg + "%" : ""}</p>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </>
            }
        </>
    );
}

function DnbResults({ element }) {
    return (
        <>
            {element.resultats_dnb === "undefined" ? <p>Pas d'examens disponibles pour cet établissement</p> :
                <>
                    <h5>Diplôme National du Brevet</h5>
                    <br />
                    {element.resultats_dnb[0].fields.region_libelle ? <p><b>Région: </b>{element.resultats_dnb[0].fields.region_libelle}</p> : <p>rien</p>}
                    <p><b>Département: </b>{element.resultats_dnb[0].fields.departement_libelle}</p>
                    <p><b>Académie: </b>{element.resultats_dnb[0].fields.academie_libelle}</p>
                    <p><b>Patronyme: </b>{element.resultats_dnb[0].fields.patronyme}</p>
                    <p><b>Commune et arrondissement: </b>{element.resultats_dnb[0].fields.commune_et_arrondissement} {element.resultats_dnb[0].fields.commune_et_arrondissement_lib_l}</p>
                    <div className="accordion" id="accordionResults">
                        {element.resultats_dnb.map((record) => (
                            <div className="accordion-item">
                                <h2 className="accordion-header" id={`heading${record.recordid}`}>
                                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target={`#collapse${record.recordid}`} aria-expanded="true" aria-controls={`collapse${record.recordid}`} data-bs-parent="">
                                        Session {record.fields.session}
                                    </button>
                                </h2>
                                <div id={`collapse${record.recordid}`} className="accordion-collapse collapse" aria-labelledby={`heading${record.recordid}`} data-bs-parent="#accordionResults">
                                    <div className="accordion-body">
                                        <p>Taux de réussite: {record.fields.taux_de_reussite}</p>
                                        {/* <Chart percentage={record.fields.taux_de_reussite}/> */}
                                        <p>Nombre d'inscrits: {record.fields.nombre_d_inscrits}</p>
                                        <p>Taux de présents: {record.fields.nombre_de_presents}</p>
                                        <p>Nombre total d'admis: {record.fields.nombre_total_d_admis}</p>
                                        <p>Nombre d'admis mention très bien: {record.fields.nombre_d_admis_mention_tb}</p>
                                        <p>Nombre d'admis mention bien: {record.fields.nombre_d_admis_mention_b}</p>
                                        <p>Nombre d'admis mention assez bien: {record.fields.nombre_d_admis_mention_ab}</p>
                                        <p>Nombre d'admis sans mention: {record.fields.nombre_d_admis_sans_mention}</p>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </>
            }
        </>
    );
}

function ModalResults({ element }) {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <>
            <button type="button" className="btn btn-outline-ef mx-auto w-100 mb-2" onClick={handleShow}><i className="fa-solid fa-file-import"></i> Voir les résultats aux examens</button>

            <Modal size="lg" show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{element.nom_etablissement} - Résultats aux examens</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {element.type_etablissement === "Collège" ? <DnbResults element={element} /> : (element.type_etablissement === "Lycée" ? <BacResults element={element} /> : <p>Pas d'examens disponibles pour cet établissement</p>)}
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="btn btn-secondary" onClick={handleClose}>Fermer</button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default ModalResults;