function Contact() {
    return (
        <>
            <div className="text-center">
                <h1 className="mb-5 text-navy">Pour nous <span className="text-orange">contacter</span>:</h1>
                <div className="container col-8">
                    <text>contact@ecolefutee.com</text>
                </div>
            </div>
        </>
    );
};

export default Contact;
