import { BeatLoader } from 'react-spinners';
import "./Loader.css";

const Loader = ({ isLoading }) => {
    return (
        <div className="loader-container">
            <BeatLoader color={'#074a8d'} loading={isLoading} size={15} />
        </div>
    );
}

export default Loader;