import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

export const Chart = ({ percentage }) => {
    // Create progress bar chart with Highcharts
	const options = {
		chart: {
			type: 'bar',
			height: 20,
			width: 100,
			margin: [0, 0, 0, 0],
			backgroundColor: '#0047ad',
		},
		title: {
			text: ''
		},
		credits: {
			enabled: false
		},
		legend: {
			enabled: false
		},
		xAxis: {
			categories: [''],
			labels: {
				enabled: false
			},
			title: {
				text: null
			},
			tickLength: 0,
			lineWidth: 0,
			minorGridLineWidth: 0,
			lineColor: 'transparent',
			minorTickLength: 0,
			tickWidth: 0
		},
		yAxis: {
			min: 0,
			max: 100,
			title: {
				text: null
			},
			labels: {
				enabled: false
			},
			gridLineWidth: 0,
			lineWidth: 0,
			minorGridLineWidth: 0,
			lineColor: 'transparent',
			minorTickLength: 0,
			tickWidth: 0
		},
		tooltip: {
			enabled: false
		},
		plotOptions: {
			bar: {
				dataLabels: {
					enabled: true,
					formatter: function () {
						return this.y + '%';
					},
					style: {
						color: '#0047ad',
						textOutline: 'none'
					}
				},
				pointWidth: 50,
				pointPadding: 0,
				groupPadding: 0,
				borderWidth: 0,
				borderRadius: 0,
				shadow: false,
				color: '#dd7c3c'
			}
		},
		series: [{
			name: 'Progress',
			data: [percentage]
		}]
	};

    return (
        <HighchartsReact highcharts={Highcharts} options={options} />
    );
};

export const ChartDNB = ({ element }) => {

	// Reverse array to have the last year in first position
	element.resultats_dnb.reverse();

	var global = [];
	element.resultats_dnb.map((result) => {
		global.push(parseFloat(result.fields.taux_de_reussite.replaceAll('%', '').replaceAll(',', '.')));
	});

    // Create charts with Highcharts about evolution of % results
	const options = {
		chart: {
			type: 'line',
			backgroundColor: 'transparent',
		},
		title: {
			text: ''
		},
		credits: {
			enabled: false
		},
		legend: {
			enabled: false
		},
		xAxis: {
			categories: element.resultats_dnb.map((result) => 
				result.fields.session
			),
			labels: {
				enabled: true
			},
			title: {
				text: null
			},
			tickLength: 0,
			lineWidth: 0,
			minorGridLineWidth: 0,
			lineColor: 'transparent',
			minorTickLength: 0,
			tickWidth: 0
		},
		yAxis: {
			min: 0,
			max: 100,
			title: {
				text: null
			},
			labels: {
				enabled: false
			},
			gridLineWidth: 0,
			lineWidth: 0,
			minorGridLineWidth: 0,
			lineColor: 'transparent',
			minorTickLength: 0,
			tickWidth: 0
		},
		tooltip: {
			enabled: false
		},
		plotOptions: {
			line: {
				dataLabels: {
					enabled: true,
					formatter: function () {
						return this.y + '%';
					},
					style: {
						color: '#0047ad'
					}
				},
				pointWidth: 50,
				pointPadding: 0,
				groupPadding: 0,
				borderWidth: 0,
				borderRadius: 0,
				shadow: false,
				color: 'orange'
			}
		},
		series: [{
			name: 'Progress',
			data: global
		}]
	};

    return (
        <HighchartsReact highcharts={Highcharts} options={options} />
    );
};

export const ChartBAC = ({ element }) => {

	// Reverse array to have the last year in first position
	element.resultats_bac.reverse();

	var st2s = [];
	var sti2d = [];
	var stl = [];
	var stmg = [];
	var total = [];

	var series = [];

	element.resultats_bac.map((result) => {
		st2s.push(parseInt(result.fields.taux_brut_de_reussite_serie_st2s));
		sti2d.push(parseInt(result.fields.taux_brut_de_reussite_serie_sti2d));
		stl.push(parseInt(result.fields.taux_brut_de_reussite_serie_stl));
		stmg.push(parseInt(result.fields.taux_brut_de_reussite_serie_stmg));
		total.push(parseInt(result.fields.taux_brut_de_reussite_total_series));
	});

	var st2s_val = false;
	var sti2d_val = false;
	var stl_val = false;
	var stmg_val = false;
	var total_val = false;

	st2s.map((result) => {
		if(!isNaN(result)) {
			st2s_val = true;
		}
	});
	if(st2s_val === true) {
		series.push({
			name: 'ST2S',
			data: st2s
		});
	}

	sti2d.map((result) => {
		if(!isNaN(result)) {
			sti2d_val = true;
		}
	});
	if(sti2d_val === true) {
		series.push({
			name: 'STI2D',
			data: sti2d
		});
	}

	stl.map((result) => {
		if(!isNaN(result)) {
			stl_val = true;
		}
	});
	if(stl_val === true) {
		series.push({
			name: 'STL',
			data: stl
		});
	}

	stmg.map((result) => {
		if(!isNaN(result)) {
			stmg_val = true;
		}
	});
	if(stmg_val === true) {
		series.push({
			name: 'STMG',
			data: stmg
		});
	}

	total.map((result) => {
		if(!isNaN(result)) {
			total_val = true;
		}
	});
	if(total_val === true) {
		series.push({
			name: 'Général',
			data: total
		});
	}

	const options = {
		chart: {
			backgroundColor: 'transparent',
		},

		title: {
			text: '',
			align: 'left'
		},
	
		yAxis: {
			title: {
				text: ''
			}
		},
	
		xAxis: {
			accessibility: {
				rangeDescription: ''
			}
		},
	
		legend: {
			layout: 'vertical',
			align: 'right',
			verticalAlign: 'middle'
		},

		credits: {
			enabled: false
		},
	
		plotOptions: {
			series: {
				label: {
					connectorAllowed: false
				},
				pointStart: parseInt(element.resultats_bac[0].fields.annee),
				dataLabels: {
					enabled: true,
					formatter: function () {
						return this.y + '%';
					},
					style: {
						color: '#0047ad'
					}
				},
			}
		},
	
		series: series,
	
		responsive: {
			rules: [{
				condition: {
					maxWidth: 500
				},
				chartOptions: {
					legend: {
						layout: 'horizontal',
						align: 'center',
						verticalAlign: 'bottom'
					}
				}
			}]
		}
	};

    return (
        <HighchartsReact highcharts={Highcharts} options={options} />
    );
};

export const ChartDNBStudent = ({ element }) => {

	// Reverse array to have the last year in first position
	element.resultats_dnb.reverse();

	var series = [];

	var absent = (element.resultats_dnb[0].fields.nombre_d_inscrits - element.resultats_dnb[0].fields.nombre_de_presents) || 0;
	var non_admis = (element.resultats_dnb[0].fields.nombre_d_inscrits - element.resultats_dnb[0].fields.nombre_total_d_admis) || 0;
	var admis = (element.resultats_dnb[0].fields.nombre_d_inscrits - element.resultats_dnb[0].fields.nombre_total_d_admis - non_admis) || 0;
	var admis_ab = element.resultats_dnb[0].fields.nombre_d_admis_mention_ab || 0;
	var admis_b = element.resultats_dnb[0].fields.nombre_d_admis_mention_b || 0;
	var admis_tb = element.resultats_dnb[0].fields.nombre_d_admis_mention_tb || 0;

	var global = element.resultats_dnb[0].fields.nombre_d_inscrits + absent;

	if(absent > 0) {
		series.push({
			name: 'Absents',
			y: absent
		});
	}
	if(non_admis > 0) {
		series.push({
			name: 'Non admis',
			y: non_admis
		});
	}
	if(admis > 0) {
		series.push({
			name: 'Admis sans mention',
			y: admis
		});
	}
	if(admis_ab > 0) {
		series.push({
			name: 'Admis avec mention AB',
			y: admis_ab
		});
	}
	if(admis_b > 0) {
		series.push({
			name: 'Admis avec mention B',
			y: admis_b
		});
	}
	if(admis_tb > 0) {
		series.push({
			name: 'Admis avec mention TB',
			y: admis_tb
		});
	}

    // Create charts with Highcharts about evolution of % results
	const options = {
		chart: {
			plotBackgroundColor: null,
			plotBorderWidth: null,
			plotShadow: false,
			type: 'pie',
			backgroundColor: 'transparent'
		},
		title: {
			text: '' + global + ' élèves',
			align: 'center'
		},
		tooltip: {
			formatter: function () {
				return '<b>' + (this.y) + '</b>';
			}
		},
		accessibility: {
			point: {
				valueSuffix: '%'
			}
		},
		credits: {
			enabled: false
		},
		plotOptions: {
			pie: {
				allowPointSelect: true,
				cursor: 'pointer',
				dataLabels: {
					enabled: false
				},
				showInLegend: true
			}
		},
		series: [{
			data: series
		}]
	};

    return (
        <HighchartsReact highcharts={Highcharts} options={options} />
    );
};

export const ChartBACStudent = ({ element }) => {

	// Reverse array to have the last year in first position
	element.resultats_bac.reverse();

	var st2s = element.resultats_bac[0].fields.effectif_presents_serie_st2s || 0;
	var sti2d = element.resultats_bac[0].fields.effectif_presents_serie_sti2d || 0;
	var stl = element.resultats_bac[0].fields.effectif_presents_serie_stl || 0;
	var stmg = element.resultats_bac[0].fields.effectif_presents_serie_stmg || 0;
	var total = element.resultats_bac[0].fields.effectif_presents_total_series || 0;

	var series = [];

	if(st2s > 0){
		series.push({
			name: 'ST2S',
			y: st2s
		});
	}

	if(sti2d > 0){
		series.push({
			name: 'STI2D',
			y: sti2d
		});
	}

	if(stl > 0){
		series.push({
			name: 'STL',
			y: stl
		});
	}

	if(stmg > 0){
		series.push({
			name: 'STMG',
			y: stmg
		});
	}

	if(total > 0){
		series.push({
			name: 'Général',
			y: total - (st2s + sti2d + stl + stmg)
		});
	}

    // Create charts with Highcharts about evolution of % results
	const options = {
		chart: {
			plotBackgroundColor: null,
			plotBorderWidth: null,
			plotShadow: false,
			type: 'pie',
			backgroundColor: 'transparent'
		},
		title: {
			text: '' + parseInt(total) + ' élèves',
			align: 'center'
		},
		tooltip: {
			formatter: function () {
				return '<b>' + (this.y) + '</b>';
			}
		},
		accessibility: {
			point: {
				valueSuffix: '%'
			}
		},
		credits: {
			enabled: false
		},
		plotOptions: {
			pie: {
				allowPointSelect: true,
				cursor: 'pointer',
				dataLabels: {
					enabled: false
				},
				showInLegend: true
			}
		},
		series: [{
			colorByPoint: true,
			data: series
		}]
	};

    return (
        <HighchartsReact highcharts={Highcharts} options={options} />
    );
};