import { Outlet, Link } from "react-router-dom";
import "./Layout.css"

const Layout = () => {
    return (
        <>
            <div className="background">
                <nav className="navbar navbar-light static-top">
                    <div className="container">
                        <div>
                            <a href="/"><img src={process.env.PUBLIC_URL + '/logo.png'} alt="Logo Annuaire Ecole Futée" className="logo" /></a>
                        </div>
                        <div className="ms-3 mt-1 d-flex justify-content-end">
                            <button className="btn btn-ef" type="button"><a href="https://ecole-futee.com" className="text-decoration-none text-light">Accéder à Ecole Futée</a></button>
                        </div>
                    </div>
                </nav>
                <div className="blank"></div>
                <Outlet />
                <footer className="footer text-light">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 h-100 text-center text-lg-start my-auto">
                                <div className="d-flex">
                                    <div className="row w-100">
                                        <div className="col-md-4">
                                            <p className="fw-bold">A propos</p>
                                            <ul className="text-light">
                                                <li><a href="https://www.ebp.com/groupe-ebp" target="_blank">Groupe EBP</a></li>
                                                <li><a href="https://www.ebp.com/temoignage" target="_blank">Cas clients</a></li>
                                                <li><a href="https://www.ebp.com/presse" target="_blank">Presse</a></li>
                                                <li><a href="https://www.ebp.com/blog" target="_blank">Blog</a></li>
                                                <li><a href="https://www.ebp-recrute.com/" target="_blank">Carrière chez EBP</a></li>
                                                <li><a href="https://www.ebp.com/partenaires-offres-emploi" target="_blank">Carrière chez nos partenaires</a></li>
                                            </ul>
                                        </div>
                                        <div className="col-md-4">
                                            <p className="fw-bold">Nous contacter</p>
                                            <ul className="text-light">
                                                <li><a href="mailto:infos.fr@ebp.com">infos.fr@ebp.com</a></li>
                                                <li><a href="tel:01 34 94 80 00">01 34 94 80 00</a></li>
                                            </ul>
                                            <p className="fw-bold">Siège social</p>
                                            <ul className="text-light list-unstyled ms-3">
                                                <li>ZA du Bel Air</li>
                                                <li>Rue de Cutesson</li>
                                                <li>78125 GAZERAN</li>
                                            </ul>
                                        </div>
                                        <div className="col-md-4">
                                            <p className="fw-bold">Suivez-nous sur les réseaux sociaux</p>
                                            <ul className="d-flex text-light list-unstyled gap-2 ms-3">
                                                <li><a href="https://www.facebook.com/ebpinformatique/"><i className="fa-brands fa-facebook"></i></a></li>
                                                <li><a href="https://twitter.com/ebp_france?lang=fr"><i className="fab fa-twitter"></i></a></li>
                                                <li><a href="https://www.youtube.com/user/EBPinformatique"><i className="fab fa-youtube"></i></a></li>
                                                <li><a href="https://www.linkedin.com/company/235667/admin/"><i className="fa-brands fa-linkedin"></i></a></li>
                                                <li><a href="https://www.instagram.com/ebp_informatique/"><i className="fab fa-instagram"></i></a></li>
                                            </ul>
                                            <div className="w-100 ms-5">
                                                <a href="https://www.ebp.com/" data-wpel-link="external" target="_blank" rel="external noopener noreferrer">
							                        <img width="200" height="92" src="https://hubbix.fr/wp-content/uploads/2022/09/LOGO_EBP_2018_RVB_monochrome_blanc_200x92.png" className="vertical-align-middle d-inline-block" alt="Logo EBP" loading="lazy"/>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <p className="text-center small mb-4 mb-lg-0">&copy; Annuaire Ecole Futée 2023. Tous droits réservés.</p> */}
                            </div>
                        </div>
                    </div>
                </footer>
            </div>
        </>
    )
};

export default Layout;
