import React from 'react';
import { useEffect } from 'react';
import { SearchBarHome } from '../Search/Search';
import "./Home.css"
import "../styles/Colors.css"
import Map from "../Map/Map";

function Home({ setSearchQuery }) {
    useEffect(() => {
        document.title = "Annuaire des établissements scolaires";
    }, []);

    return (
        <>
            <div className="">
                <div className="container position-relative section">
                    <div className="row justify-content-center">
                        <div className="h-25">
                            <div className="text-center text-white">
                                <h1 className="mb-5 text-ef">Toute l'information des <span className="text-orange">établissements</span> scolaires</h1>
                                <h3 className="mb-5 text-secondary">L'annuaire simple et complet</h3>
                                <SearchBarHome setSearchQuery={setSearchQuery} />
                                <a className="mt-3 text-ef text-decoration-none fw-bold" href="recherche?advanced=1">Recherche avancée</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-12">
                        <div className="card card-body">
                            <Map />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Home;